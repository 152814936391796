import Constants from '@constants'
import { getCategoriesApi } from './api'
import { getHierarchyConfigApi } from '@components/hierarchy-config/hierarchy-api'
import { isLoggedIn } from '@utils/auth'

function getCategoryModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    categories: [],
  }

  vuexModule.getters = {
    categories(state) {
      return state.categories
    },
  }

  vuexModule.mutations = {
    SET_CATEGORY(state, data) {
      state.categories = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetch')
      }
    },

    fetch({ commit, rootGetters }, params) {
      const availableModules = rootGetters['license/availableModules']
      const assetChildModules =
        availableModules.indexOf(Constants.ASSET) >= 0
          ? [
              Constants.ASSET_HARDWARE,
              Constants.ASSET_SOFTWARE,
              Constants.ASSET_NON_IT,
              Constants.ASSET_CONSUMABLE,
            ]
          : []
      if (
        [...availableModules, ...assetChildModules].indexOf(moduleName) >= 0
      ) {
        getCategoriesApi(moduleName, false, params).then((data) => {
          commit('SET_CATEGORY', Object.freeze(data))
        })
      }
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_CATEGORY', [])
    },
  }
  return vuexModule
}
function getCategoryConfig() {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    categoryConfig: {},
  }

  vuexModule.getters = {
    categoryConfig(state) {
      return state.categoryConfig
    },
    allowedRequestLeafNode(state) {
      return state.categoryConfig.allowedRequestLeafNode
    },
    allowedProblemLeafNode(state) {
      return state.categoryConfig.allowedProblemLeafNode
    },
    allowedChangeLeafNode(state) {
      return state.categoryConfig.allowedChangeLeafNode
    },
    allowedReleaseLeafNode(state) {
      return state.categoryConfig.allowedReleaseLeafNode
    },
    reportHierarchy(state) {
      return state.categoryConfig.reportHierarchy
    },
    requestStartingNumber(state) {
      return state.categoryConfig.requestStartingNumber
    },
    curruentRequestNumber(state) {
      return state.categoryConfig.curruentRequestNumber
    },
    requestDefaultCategoryPrefix(state) {
      return state.categoryConfig.requestDefaultCategoryPrefix
    },
  }

  vuexModule.mutations = {
    SET_HIERARCHY_CONFIG(state, data) {
      state.categoryConfig = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetchConfig')
      }
    },

    fetchConfig({ commit, rootGetters }, params) {
      getHierarchyConfigApi(Constants.CATEGORY).then((data) => {
        commit('SET_HIERARCHY_CONFIG', Object.freeze(data))
      })
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_HIERARCHY_CONFIG', {})
    },
  }
  return vuexModule
}

export const modules = {
  request: getCategoryModule(Constants.REQUEST),
  problem: getCategoryModule(Constants.PROBLEM),
  change: getCategoryModule(Constants.CHANGE),
  release: getCategoryModule(Constants.RELEASE),
  serviceCatalog: getCategoryModule(Constants.SERVICE_CATALOG),
  asset_software: getCategoryModule(Constants.ASSET_SOFTWARE),
  categoryConfig: getCategoryConfig(),
}
