import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`customRules/${Constants.REQUEST}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`customRules/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`customRules/${Constants.CHANGE}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`customRules/${Constants.RELEASE}`)
const { mapGetters: purchaseGetters, mapActions: purchaseActions } =
  createNamespacedHelpers(`customRules/${Constants.PURCHASE}`)
const { mapGetters: projectGetters, mapActions: projectActions } =
  createNamespacedHelpers(`customRules/${Constants.PROJECT}`)

export const CustomRulesComputed = {
  ...requestGetters({
    requestNoteRequiredFields: 'fields',
    requestIsFieldRequiredNote: 'fieldRequiresNote',
    requestDialogFields: 'dialogFields',
    requestIsFieldDialog: 'fieldDialog',
  }),
  ...problemGetters({
    problemNoteRequiredFields: 'fields',
    problemIsFieldRequiredNote: 'fieldRequiresNote',
    problemDialogFields: 'dialogFields',
    problemIsFieldDialog: 'fieldDialog',
  }),
  ...changeGetters({
    changeNoteRequiredFields: 'fields',
    changeIsFieldRequiredNote: 'fieldRequiresNote',
    changeDialogFields: 'dialogFields',
    changeIsFieldDialog: 'fieldDialog',
  }),
  ...releaseGetters({
    releaseNoteRequiredFields: 'fields',
    releaseIsFieldRequiredNote: 'fieldRequiresNote',
    releaseDialogFields: 'dialogFields',
    releaseIsFieldDialog: 'fieldDialog',
  }),
  ...purchaseGetters({
    purchaseNoteRequiredFields: 'fields',
    purchaseIsFieldRequiredNote: 'fieldRequiresNote',
    purchaseDialogFields: 'dialogFields',
    purchaseIsFieldDialog: 'fieldDialog',
  }),
  ...projectGetters({
    projectNoteRequiredFields: 'fields',
    projectIsFieldRequiredNote: 'fieldRequiresNote',
    projectDialogFields: 'dialogFields',
    projectIsFieldDialog: 'fieldDialog',
  }),
}

export const CustomRulesMethods = {
  ...requestActions({
    requestFetchNoteRules: 'fetch',
    requestFetchDialogRules: 'fetch',
  }),
  ...problemActions({
    problemFetchNoteRules: 'fetch',
    problemFetchDialogRules: 'fetch',
  }),
  ...changeActions({
    changeFetchNoteRules: 'fetch',
    changeFetchDialogRules: 'fetch',
  }),
  ...releaseActions({
    releaseFetchNoteRules: 'fetch',
    releaseFetchDialogRules: 'fetch',
  }),
  ...purchaseActions({
    purchaseFetchNoteRules: 'fetch',
    purchaseFetchDialogRules: 'fetch',
  }),
  ...projectActions({
    projectFetchNoteRules: 'fetch',
    projectFetchDialogRules: 'fetch',
  }),
}
