import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`category/${Constants.REQUEST}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`category/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`category/${Constants.CHANGE}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`category/${Constants.RELEASE}`)
const { mapGetters: serviceCatalogGetters, mapActions: serviceCatalogActions } =
  createNamespacedHelpers('category/serviceCatalog')
const {
  mapGetters: softwareCategoryGetters,
  mapActions: softwareCategoryActions,
} = createNamespacedHelpers(`category/${Constants.ASSET_SOFTWARE}`)
const { mapGetters: categoryConfigGetters, mapActions: categoryConfigActions } =
  createNamespacedHelpers('category/categoryConfig')

export const CategoryComputed = {
  ...requestGetters({
    requestCategories: 'categories',
  }),
  ...problemGetters({
    problemCategories: 'categories',
  }),
  ...changeGetters({
    changeCategories: 'categories',
  }),
  ...releaseGetters({
    releaseCategories: 'categories',
  }),
  ...serviceCatalogGetters({
    serviceCatalogCategories: 'categories',
  }),
  ...softwareCategoryGetters({
    asset_softwareCategories: 'categories',
  }),
  ...categoryConfigGetters({
    categoryConfig: 'categoryConfig',
    requestOnlyLeafNodeSelectable: 'allowedRequestLeafNode',
    problemOnlyLeafNodeSelectable: 'allowedProblemLeafNode',
    changeOnlyLeafNodeSelectable: 'allowedChangeLeafNode',
    releaseOnlyLeafNodeSelectable: 'allowedReleaseLeafNode',
    reportHierarchy: 'reportHierarchy',
    requestStartingNumber: 'requestStartingNumber',
    curruentRequestNumber: 'curruentRequestNumber',
    requestDefaultCategoryPrefix: 'requestDefaultCategoryPrefix',
  }),
}

export const CategoryMethods = {
  ...requestActions({
    requestCategoriesFetch: 'fetch',
  }),
  ...problemActions({
    problemCategoriesFetch: 'fetch',
  }),
  ...changeActions({
    changeCategoriesFetch: 'fetch',
  }),
  ...releaseActions({
    releaseCategoriesFetch: 'fetch',
  }),
  ...serviceCatalogActions({
    serviceCatalogCategoriesFetch: 'fetch',
  }),
  ...softwareCategoryActions({
    asset_softwareCategoriesFetch: 'fetch',
  }),
  ...categoryConfigActions({
    categoryConfigFetch: 'fetchConfig',
  }),
}
