import api from '@api'

export const transformRequiredNoteRules = (rule) => {
  return {
    id: rule.id,
    items: (rule.propKeysForNotes || []).concat(rule.customfieldForNotes || []),
  }
}
export const transformDialogRules = (rule) => {
  return {
    id: rule.id,
    items: (rule.propKeysForDialog || []).concat(
      rule.customfieldForDialog || []
    ),
  }
}

export function requiredNoteRulesApi(moduleName) {
  return api
    .get(`/${moduleName}/requirednoterule`)
    .then(transformRequiredNoteRules)
}

export function dialogRulesApi(moduleName) {
  return api.get(`/${moduleName}/showdialogrule`).then(transformDialogRules)
}
